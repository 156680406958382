<template>
  <v-dialog v-model="dialog.show" max-width="500px" :persistent="dialog.return" v-if="notPrerendering">
    <v-card class="pb-3">
      <v-card-title class="primary pt-2 pb-3">

      </v-card-title>

      <v-card-text class="pa-0">
        <v-layout row wrap>
          <v-flex xs12 class="px-5 pt-3">
            <p class="graphik-medium normalcase font-45 primary--text mb-0">{{ dialog.title }}</p>
            <p class="graphik-light normalcase font-20 dark-grey--text">{{ dialog.message }}</p>
          </v-flex>

          <v-flex xs12 :text-xs-center="dialog.has_action" :text-xs-right="!dialog.has_action" class="px-5 pb-3">
            <v-btn 
              color="primary"
              depressed dark 
              class="normalcase graphik-bold-italic ma-0 border-radius-5"
              v-if="dialog.has_action"
              @click.native="dialog.action"
            >{{ $ml.get('general_accept') }}<v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>
            
            <v-btn 
              color="primary"
              depressed dark 
              class="normalcase graphik-bold-italic ma-0 border-radius-5"
              v-if="dialog.has_action"
              @click.native="dialog.show = false"
            ><v-icon dark small class="mr-2">arrow_back</v-icon>{{ $ml.get('general_cancel') }}</v-btn>
            
            <v-btn 
              color="primary"
              depressed dark 
              class="normalcase graphik-bold-italic ma-0 border-radius-5"
              v-else-if="!dialog.return"
              @click.native="dialog.show = false"
            >{{ $ml.get('general_accept') }}<v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>
            
            <v-btn 
              color="primary"
              depressed dark 
              class="normalcase graphik-bold-italic ma-0 border-radius-5"
              v-else
              :to="{path: dialog.path_to_return}"
              @click.native="dialog.show = false"
            >{{ $ml.get('general_accept') }}<v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MessageDialog',
  /*
    dialog: {
      title: string,
      message: string,
      show: boolean,
      return: boolean,
      has_action: boolean,
      action: function().bind(this)
    }
  */
  props: {
    dialog: Object
  },
  computed: {
    notPrerendering () {
      return (!window.__PRERENDER_INJECTED)
    }
  }
}
</script>
